import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
//import Close from "@material-ui/icons/Cancel";
//import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import Hidden from '@material-ui/core/Hidden';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import logo from "assets/img/sellanizer_logo.png";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

// 44 components
import API from 'components/44/ApiPlanungswelten';
import { withSnackbar } from 'notistack';
import LoadingButton from "./LoadingButton";

let pjson = require("./../../../package.json")

let CryptoJS = require("crypto-js")

let CryptoJSAesJson = {
  stringify: function (cipherParams) {
      var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
  },
  parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams;
  }
}

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered

    this.state = {
      cardAnimaton: "cardHidden",
      username: localStorage.getItem('username') || '',
      password: this.getPasswordFromStorage() || '',
      message: ''
    };
  }

  componentDidMount() {
    //console.debug('LoginPage:this.props', this.props)
    if(this.props.location && this.props.location.pathname==='/logout'){
      this.props.history.push('/')
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  handleUsernameChange(e) {
    //console.log(e.target.value);
   this.setState({username: e.target.value});
  }

  handlePasswordChange(e) {
   this.setState({password: e.target.value});
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  }

  handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  getPasswordFromStorage(){
    let pass = localStorage.getItem('password') || '';
    if(pass.includes('{"ct"')){
      return API.decrypt(pass)
    }else{
      return pass
    }
  }

  async handleLogin() {
    //Loading animation for the login button
    this.setState({ loading: true });
    localStorage.setItem('username', this.state.username);
    localStorage.setItem('password', API.encrypt(this.state.password));
    //console.log('handleLogin', this.state);

    // Unnötige Logginversuche vermeiden
    if(this.state.isLoggedIn && this.state.token){
      return true;
    }

    let params = { username: this.state.username, password: this.state.password, user: this.state.username }
    let _this = this

    await API.loginAndSavePartnerDaten(params)
      .then(
        (result) => {
          console.debug('Login Result', result )

          // if Login was valid
          if(result !== "undefined" && typeof result.code === "undefined" && result.token.length > 1 ){

            let partner_data = {};

            if(typeof result.partners != "undefined"){
              partner_data = result.partners[0] || false;
              //console.debug('set partner_id', partner_data.id);
            }

            let user_role = JSON.parse(CryptoJS.AES.decrypt(result.user_role, "user_id#"+result.user_id, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));

            if(!user_role.includes('sellanizer')){
              const message = "Ihre Rolle ist nicht berechtigt!";
              let error = <div className={'snackbar-error'} dangerouslySetInnerHTML={{__html: (message || '')}} />;
              this.props.enqueueSnackbar('Beim Login ist ein Fehler aufgetreten!', { variant: 'error', action: error, autoHideDuration: 5000})
              _this.setState({
                isLoggedIn: false,
                user_display_name: false,
                user_nicename: false,
                message: message,
                token: false,
                token_expires_at: 0,
              })
            }else{
              _this.setState({
                user_display_name: result.user_display_name || false,
                user_nicename: result.user_nicename || false,
                user_id: result.user_id || false,
                user_role: result.user_role || false,
                user_has_generate_ai_texts: result.user_has_generate_ai_texts || false,
                message: result.message || false,
                token: result.token || false,
                token_expires_at: result.token_expires_at || false,
                partner_id: (typeof partner_data.id != "undefined") ? partner_data.id : false,
                partner_data: partner_data || false,
                //facebook_data: facbook_data || false,
                partners: result.partners || false,
              })

              //localStorage.setItem('partnerData', JSON.stringify(this.state.partner_data));
              localStorage.setItem('selected_partner_id', this.state.partner_id);
              localStorage.setItem('partners', JSON.stringify(this.state.partners));
              // Token in localStorage speichern, könnte man dann im Session-Constructor auslesen
              localStorage.setItem('token', this.state.token);
              localStorage.setItem('token_expires_at', this.state.token_expires_at);
              localStorage.setItem('user_id', this.state.user_id);
              localStorage.setItem('user_role', this.state.user_role);
              localStorage.setItem('user_has_generate_ai_texts', this.state.user_has_generate_ai_texts);
              //localStorage.setItem('partnerLogo', this.state.partner_data.partner_logo);
              //localStorage.setItem('partnerName', this.state.partner_data.title);

              // Get partners which has wrong Kleinanzeigen credentials and save it to the localstorage
              let wrongEbayCredentialsPartner = [];

              // eslint-disable-next-line
              this.state.partners.map(partner => {
                if(partner.is_ebay_kleinanzeigen && typeof partner.ebay_wrong_credentials != "undefined" && partner.ebay_wrong_credentials) {
                  wrongEbayCredentialsPartner.push(partner.id);
                }
              })

              localStorage.setItem("partnersWrongEbayCredentials", JSON.stringify(wrongEbayCredentialsPartner));
              localStorage.removeItem('news_shown');

              // Parent Session probs setzen
              _this.props.handleSetState({isLoggedIn: true, token: this.state.token, token_expires_at: this.state.token_expires_at })
              _this.setState({isLoggedIn: true, token: result.token, token_expires_at: result.token_expires_at });
            }
          }else{
            console.log('Error beim Login: TODO: Fehlerausgabe', result)

            let error = <div className={'snackbar-error'} dangerouslySetInnerHTML={{__html: (result.message || '')}} />;
            this.props.enqueueSnackbar('Beim Login ist ein Fehler aufgetreten!', { variant: 'error', action: error, autoHideDuration: 5000})
            //console.log('Error beim Request1:', error);
            _this.setState({
              isLoggedIn: false,
              user_display_name: false,
              user_nicename: false,
              message: result.message,
              token: false,
              token_expires_at: 0,
            })
          }
        },
        (error) => {
          console.log('Error beim Request1:', error);
          this.props.enqueueSnackbar('Beim Login ist ein Fehler aufgetreten! ('+error+')', { variant: 'error'})
          _this.setState({
            isLoggedIn: false,
            user_display_name: false,
            user_nicename: false,
            message: error,
            token: false,
            token_expires_at: 0,
            loading: false
          })
        }
    )
    .catch(response => () => {
      this.props.enqueueSnackbar('Beim Login ist ein Fehler aufgetreten! (2: '+response+')', { variant: 'error'})
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading } = this.state;

    //let strip_tags = require("locutus/php/strings/strip_tags");
    let strip_tags = function (text) {
      // TODO
      return text
    };
    const { classes } = this.props;
    return (
      <div className={`${classes.content} ${classes.animationClass}`}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={6} lg={5} >
              <form>
                <a target="_blank" rel="noopener noreferrer" href="https://sellanizer.net" style={{display: 'block', textAlign: 'center'}}>
                  <img style={{maxWidth: '280px', width: '100%'}} src={logo} alt="Sellanizer" />
                </a>
                <Card style={{marginTop: '10px'}} className={classes[this.state.cardAnimaton]}>
                  {/*<CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="danger"
                  >
                  </CardHeader>*/}
                  {(!this.state.message && this.state.isLoggedIn) ?
                    <CardBody>
                      <h2>Login erfolgreich!</h2>
                      <h4>bitte warten...</h4>
                    </CardBody> :
                    <div>
                    <CardBody>
                      {(this.state.message) ? <h4><div dangerouslySetInnerHTML={{__html: strip_tags(this.state.message || '', '<strong><a>')}} /></h4> : ''}
                      <CustomInput
                        labelText="Benutzer/E-Mail"
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.username,
                          onChange: (e) => this.handleUsernameChange(e),
                          onKeyPress: (e) => this.handleKeyPress(e),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'password',
                          value: this.state.password,
                          autoComplete: 'password',
                          onChange: (e) => this.handlePasswordChange(e),
                          onKeyPress: (e) => this.handleKeyPress(e),
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <LoadingButton
                          size="lg"
                          color="primary"
                          loading={loading}
                          onClick={() => this.handleLogin()}
                      >
                        Einloggen
                      </LoadingButton>
                    </CardFooter>
                  </div>}
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>

        <div style={{textAlign: 'center', bottom: 10}}>
          <h5><a href="https://sellanizer.net/" target="_blank" rel="noopener noreferrer" style={{cursor: 'pointer'}}>Noch kein Sellanizer-Kunde?</a></h5>
        </div>

        <div style={{textAlign: 'center', bottom: 10}}>
          <small style={{color: 'red'}}><a href="https://sellanizer.net/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz</a> &nbsp; <a href="https://sellanizer.net/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a></small>
        </div>

        <GridContainer style={{position: 'absolute', bottom: 5, left: 0, zIndex:1}}>
          <Hidden smDown>
            <div style={{position: 'absolute', bottom: 10, left: 10, zIndex:1}}>
              <small style={{color: 'white'}}>Version: &nbsp;{pjson.version}</small>
              <small style={{color: 'white', paddingLeft: 10, fontWeight: 'bold'}}>&copy;{new Date(Date.now()).getFullYear()} Trends4Markets GmbH Alle Rechte vorbehalten.</small>
            </div>
          </Hidden>
          <Hidden mdUp>
            <GridItem xs={12} sm={12} md={2}>
              <small style={{color: 'white', paddingRight: 10}}>Version: &nbsp;{pjson.version}</small>
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <small style={{color: 'white', fontWeight: 'bold'}}>&copy;{new Date(Date.now()).getFullYear()} Trends4Markets GmbH Alle Rechte vorbehalten.</small>
            </GridItem>
          </Hidden>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(loginPageStyle)(LoginPage));
